import axios from 'axios';
import { Message } from 'element-ui';

/**
 * ajax 封装
 * 
 */
class Ajax {
    /**
     * 
     */
    constructor() {
        this.axios = axios.create({
            timeout: 30000,
        });

        // 请求拦截器
        this.axios.interceptors.request.use(config => {
            return config;
        }, error => {
            Message.error({ message: '发起请求失败。' });
            return Promise.reject(error);
        });

        // 响应拦截器
        this.axios.interceptors.response.use(response => {
            if (response.data.code == -99) {
                Message.error({ message: response.data.tip });
                return Promise.reject(response.data);
            }
            return response.data;
        }, error => {
            window.console.log(error);
            let data = JSON.parse(error.config.data || '{}');
            let response = error.response;
            if (!data.silent && response.data.code) {
                Message.error({ message: response.data.tip });
            }
            return Promise.reject(response.data);
        });
    }

    /**
     * 
     * @param {*} url 
     * @param {*} config 
     */
    get(url, config) {
        return this.axios.get(url, config);
    }

    /**
     * 
     * @param {*} url 
     * @param {*} data 
     * @param {*} config 
     */
    post(url, data, config) {
        return this.axios.post(url, data, config);
    }

    /**
     * 
     * @param {*} url 
     * @param {*} data 
     * @param {*} config 
     */
    upload(url, data, config) {
        let sheet = new FormData();
        for (let key in data) {
            sheet.append(key, data[key]);
        }
        return this.axios.post(url, sheet, config);
    }

    /**
     * 
     * @param {} config 
     */
    request(config) {
        return this.request(config);
    }
}

export const ajax = new Ajax();

export default {
    install(Vue) {
        Vue.prototype.$ajax = ajax;
    },
};