import {
    ajax
} from "@/plugins/axios";
export const URL = {
    login: "/api/login"
}

export const loginApi = () => {
    let func = {}
    for (let key in URL) {
        func[key] = (params = {}) => {
            return ajax.post(
                URL[key],
                params
            );
        }
    }
    return func;
}